<template>
  <div class="pin__body">
    <h2 class="pin__title">{{ title }}</h2>

    <ui-panel>
      <pinboard
        v-model="pin"
        @input="key++"
        class="onboarding__pinboard"
        :key="step"
      />

      <span
        v-if="!isConfirmCorrect || invalidPin"
        class="pin__error"
      >{{ $t('conn3ct-wallet.pin_confirm_error') }}</span>
    </ui-panel>

    <actions-button
      @click="onConfirm"
      :appearance="$pepper.Appearance.PRIMARY"
      class="onboarding__action"
      :disabled="!isValid"
    >{{ $t('conn3ct-wallet.confirm_action') }}</actions-button>

    <button
      @click="onCancel"
      class="pin__cancel"
    >{{ $t('conn3ct-wallet.cancel') }}</button>
  </div>
</template>

<script>
import Pinboard from '@/components/forms/pinboard.vue'

export default {
  name: "SaylConnectPin",

  components: {
    Pinboard,
  },

  data() {
    return {
      pin: [],
      pinCpy: null,

      step: this.$basil.get(this.$route, 'params.step', 1),
      key: 0,
      isOptional: false,
      invalidPin: false
    }
  },

  computed: {
    isValid() {
      return this.key && this.pin.length === 5 && this.isConfirmCorrect
    },

    isConfirmCorrect() {
      const pin = sessionStorage.getItem('pin')
      if(this.step === 1 || this.pin.length < 5) return true

      const res = this.key && this.pin.join('') === pin

      if(!res) {
        this.invalidPin = true
        this.goBack()
      }

      return res
    },

    title() {
      return this.step === 1 ?
        this.$t('conn3ct-wallet.login_pin_create_title') :
        this.$t('conn3ct-wallet.login_pin_confirm_title')
    },
  },

  methods: {
    clearPin() {
      this.pin = []
    },

    onCancel() {
      if(this.step === 2) {
        this.goBack()
        return
      }

      sessionStorage.removeItem('pin')
      this.$emit('next', this.isOptional)
    },

    goBack() {
      this.step = 1
      sessionStorage.removeItem('pin')

      this.clearPin()
      this.$router.replace({ name: 'sayl-connect_onboarding-pin', params: { step: 1 } }).catch(() => {})

    },

    onConfirm() {
      if(this.step === 1) {
        this.invalidPin = false
        sessionStorage.setItem('pin', this.pin.join(''))
        this.step = 2
        this.$emit('step', 2)
        this.clearPin()
        this.$router.replace({ name: 'sayl-connect_onboarding-pin', params: { step: 2, isOptional: this.isOptional } }).catch(() => {})
      } else if(this.step === 2) {
        this.$user.setPin(this.pin.join(''))
          .then(() => this.$user.getUser())
          .then(user => {
            if(user.lang) {
              this.setLocale(user.lang)
            }
            this.$emit('next', this.isOptional)
          })
          .catch((e) => $console.error(e))
      }
    },
  },

  created() {
    this.isOptional = this.$route.params?.isOptional ?? false
  },

  mounted() {
    window.scrollTo(0, 0)
    this.step = this.$basil.isNil(this.created) ? 1 : 2

    if(this.$user.user.pinSet) {
      this.$emit('next', this.isOptional)
    }
  },
}
</script>
